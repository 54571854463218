.cta{
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    flex:1;
    /* text-align: center; */
    padding: 3rem 2rem;
    color: white;
    /* background-color: rgb(236, 109, 49); */
}
.cta-btn{
  
    font-size: 1rem;
    font-weight: 600;
    margin: 2rem;
    padding: 0.5rem 2rem;
    border: 2px solid ;
    background-color: white;
    color: var(--clr-primary);
    border-radius: 20px 0 20px 0;
    cursor: pointer;
    transition: all ease 0.5s;
}
.cta-btn:hover{
    color: white;
    background-color: var(--clr-primary);
    border: 1px solid white;
    scale: 1.2;
}
@media screen and (max-width: 700px) {
    .cta{
        flex-direction: column;
    }
    
}