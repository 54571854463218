.tabs-container {
    display: flex;
    /* height: 100vh; */
    gap: 4rem;
    position: relative;
    padding: 1rem;
    background: #ffffff;
    border: 1px solid rgba(0, 0, 0, 0.274);
  }
  
  .bloc-tabs {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    min-width:14rem;
    max-width: 14rem;
    gap: 0.5rem;
  }
  
  .tabs {  
    padding: 15px;
    background: rgba(255, 255, 255, 0.788);
    box-shadow: 1px 0px 2px 1px rgba(170, 255, 255, 0.613);
    /* color: var(--clr-primary); */
    color: var(--clr-secondary);
    cursor: pointer;
    /* border-bottom: 1px solid rgba(0, 0, 0, 0.274); */
    max-width: 100%;
    border-radius: 0 0 8px 8px ;
    position: relative;
    outline: none;
    font-weight: 600;
  }

  /* .tabs:not(:last-child){
    border-right: 1px solid rgba(0, 0, 0, 0.274);
  } */
  
  .active-tabs  {
    /* background: var(--clr-primary); */
    background: rgb(245, 251, 254);
    /* color: white; */
    /* color: var(--clr-primary); */
    box-shadow: 1px 1px 4px 1px rgb(161, 161, 231);
    border-bottom: 1px solid transparent;
  }
  
  .active-tabs::before {
    content: "";
    display: block;
    position: absolute;
    top: -5px;
    left: 50%;
    transform: translateX(-50%);
    box-shadow: 1px 1px 1px 1px white;
    width: calc(100% + 2px);
    height: 5px;
    background: rgba(0, 110, 255, 0.932);
  }
  
  
  
  .bloc-tabs > button {
    border: none;
    padding: 0.4rem 1rem; 
    width: 100%;  
    font-size: 1rem;
    font-weight: 500;
  }
 
  button p svg{
    font-size: 2rem;
    margin: 0.5rem;
    /* background-color: rgb(255, 255, 255); */
  }

  .content-tabs {
    display: flex;
    /* flex-grow : 1; */
    justify-content: center;
    align-items: center;
  }

  .tabs-content {
    text-align: center;
    background: white;
    margin: 4rem auto;
    padding: 5rem;
    box-shadow: 2px 2px 5px 2px rgb(161, 161, 231);
    width: 100%;
    display: none;
  }

  .tabs-content h2 {
    /* color: var(--clr-primary); */
    color: var(--clr-secondary);
    margin: 1rem auto;
    /* padding: 5rem;  */
  }

  /* .content hr {
    width: 100px;
    height: 2px;
    background: #222;
    margin-bottom: 5px;
  } */

  .tabs-content p {
    width: 100%;
    height: 100%;
  }
 
  .active-content {
    display: block;
  } 
  aside {
    text-align: left;
    margin: 1rem;
  }
  aside p{
    margin: 0.5rem;
    display: flex;
    align-items: center;
    justify-content: flex-start;
  }
  aside p svg{
    /* color: var(--clr-primary); */
    color: var(--clr-secondary);
    margin: 0 0.3rem;
    
  }
  .tabs-content button{
    outline: none;
  }

  .tabs-btn{
    font-weight: 500;
    color: #ffffff;
    font-size: 1rem;
    padding: 0.6rem 1rem;
    cursor: pointer;
    margin: 2rem auto 1rem auto;
    padding: 0.5rem 3rem;
    border-radius: 20px;
    border: 1px solid black;
    background-color: var(--clr-secondary);
    transition: all 0.3s ease;
  }
  .tabs-btn a{
    color: white;
    transition: all 0.3s ease;
  }
  .tabs-btn:hover{
    background-color: white;
    color: var(--clr-secondary);
    border-color: var(--clr-secondary);
  }
  .tabs-btn:hover a{
    /* background-color: white; */
    color: var(--clr-secondary);
  }
 
  @media screen and (max-width: 800px) {
    .tabs-content {
      
      padding: 5rem 3rem;
     
  }
  .bloc-tabs {
   
    min-width: 10rem;
    max-width: 20rem;
}
    
}
  @media screen and (max-width: 700px) {
    .tabs-container {
      gap: 2rem;
     flex-direction: column;
     align-items: center;
     
    }
    
}
  @media screen and (max-width: 550px) {
    .bloc-tabs {  
      max-width: 100%;
  }
  
  .tabs-container {
  gap: 1rem;
}
.tabs-content {
  padding: 2rem 0;
}

    
}