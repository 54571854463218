.about {
  margin: 0;
  /* padding: 3rem; */
}
.parallax-content h4 {
  color: var(--clr-primary);
  /* font-size: 3rem; */
  background-color: rgba(24, 24, 24, 0.438);
  max-width: max-content;
  margin: auto;
  padding: 1rem 4rem;
}

.parallax {
  /* The image used */
  background-image: url("../../Assets/bg_11.jpg");

  /* Set a specific height */
  min-height: 500px;

  /* Create the parallax scrolling effect */
  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  /* background-color: rgba(250, 3, 3, 0.863); */
}

.parallax-content {
  background-color: rgba(0, 0, 0, 0.938);
  background-size: cover;
  text-align: center;
}

.parallax-content-right {
  float: right;
  text-align: right;
  vertical-align: middle;
  margin: 1rem 0.5rems;
  padding: 2rem 4rem;
  height: 100%;
  width: 65%;
  background-color: rgba(0, 0, 0, 0.274);
}

.parallax-content-right h3 {
  font-size: 2rem;
  color: white;
}

.parallax-content-right p {
  color: white;
  font-weight: 500;
}

.parallax-content-right h3,
.parallax-content-right p {
  margin: 1rem;
  line-height: 2rem;
}

@media screen and (max-width: 1050px) {
  .parallax-content-right {
    width: 80%;
    padding: 2rem 3rem;
  }
}
@media screen and (max-width: 800px) {
  .parallax-content-right {
    width: 85%;
    padding: 2rem;
  }
}

@media screen and (max-width: 750px) {
  .parallax-content-right {
    width: 90%;
  }
}

@media screen and (max-width: 700px) {
  .parallax {
    min-height: 600px;
  }

  .parallax-content-right {
    width: 100%;
    padding: 5rem 0.5rem 2rem 0.5rem;
    text-align: center;
  }
  .parallax-content-right h3 {
    font-size: 1.5rem;
  }
}

@media screen and (max-width: 550px) {
  .parallax {
    /* padding: 4rem 1rem; */
    min-height: 600px;
  }
  /* .parallax-content {
        margin-top: 2rem;
    } */
}
