.feature-container {
    display: flex;
    flex: 1 1 auto;
    justify-content: space-evenly;
    align-items: center;
    font-weight: 500;
    gap: 2rem;
}


    

#feature-right {
    display: flex;
    /* flex-direction: row-reverse; */

}


.feature-container-image {
    flex-basis: 50%;
    margin: 1rem 0;
    /* width: 50%; */
}

/* .feature-container-image img {
    width: auto;
    height: 230px;
} */

.feature-container-content {
    /* display: flex;
    justify-content: flex-start;
    flex-direction: column;
    align-items: center; */
    color: black;
    margin: 1.5rem 0;
    width: 50%;
    font-weight: 500;
}

.feature-container-content h2,
.feature-container-content p {
    margin: 1rem;
    line-height: 1.6rem;
}

.feature-container-content h2 {
    font-family: system-ui;
}

/* .sub-title {
    color: rgb(0, 174, 255);
    font-size: 12px;
} */

@media screen and (max-width: 1050px) {
    /* .feature-container-image img {
        width: auto;
        height: 200px;
    } */

    .feature-container-content {
        margin: 1rem 0;
        width: 350px;
    }
}

@media screen and (max-width: 900px) {
    /* .feature-container-image img {
        width: auto;
        height: 180px;
    } */

}


@media screen and (max-width: 853px) {
    /* .feature-container-image img {
        width: auto;
        height: 160px;
    } */

    .feature-container-content h2 {
        font-size: 20px;

    }

    .feature-container-content p {
        font-size: 10px;
    }

}

@media screen and (max-width: 750px) {
    .feature-container {
        flex-direction: column;
    }

    #feature-right {
        flex-direction: column;

    }

    .feature-container {
        justify-content: center;
        text-align: center;
    }

    .feature-container-content {
        justify-content: center;
        text-align: center;
        align-items: center;
        /* margin: 1rem; */
        width: auto;
    }


}

@media screen and (max-width: 750px) {

    .feature-container {
        margin: 0.5rem;
        padding: 0.5rem;
    }

    .feature-container-image {
        margin: 1rem 0 0 0;
    }

    .feature-container-content h2,
    .feature-container-content p {
        margin: 0.5rem;
    }
}



@media screen and (max-width: 550px) {
    .section_padding {
        padding: 2rem;
    }

    .feature-content {
        width: 100%;
        margin: 1rem 0;
    }

    .feature-content h1 {
        margin: 0.5rem;
        font-size: 18px;
        padding: 0.5rem
    }

    .feature-content p {
        margin: 0;
        line-height: normal;
        font-size: 10px;
    }

    /* .feature-container-image img {
        
        height: auto;
    } */

    .feature-container-content {
        margin: auto;
    }
}