.hero-section {
  background-image: url(../../Assets/bg_13.jpg);
  /* background-image: url(../assets/bg_2.jpg); */
  background-size: cover;
  height: 100vh;
}

.hero {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  height: 100%;
  /* background-color: rgb(0 0 0 / 26%); */
  background-color: rgb(86 93 102 / 26%);
}
.title {
  color: var(--clr-primary);
  /* margin:auto; */
  font-size: 6rem;
  letter-spacing: 1rem;
  font-style: italic;
  font-family: "Franklin Gothic Medium", "Arial Narrow", Arial, sans-serif;
}
/*   */

.hero h2 {
  color: var(--clr-secondary);
  padding: 0.5rem 2rem;
  margin: 0 auto 2rem auto;
  background-color: #0000004d;
  font-style: italic;
  border-radius: 5px;
}
/* .span_U {
  color: rgb(113, 203, 255);
} */
.hero h3 {
  position: relative;
}

.hero h3::after {
  content: "";
  position: absolute;
  height: 100%;
  width: 100%;
  background: black;
  border-left: 2px solid var(--clr-primary);
  animation: typing 1.5s steps(2) infinite;
}
@keyframes typing {
  100% {
    left: 100%;
    margin: 0 -10px 0 10px;
  }
}
.hero p {
  color: var(--clr-primary);
  max-width: 80%;
  margin: 1rem auto;
  text-align: center;
  font-weight: 500;
  letter-spacing: 1px;
  word-spacing: 2px;
  word-wrap: normal;
}
@media screen and (max-width: 800px) {
  .hero {
    text-align: center;
    padding: 5rem 1rem;
  }
}
@media screen and (max-width: 800px) {
  .title {
    font-size: 5rem;
  }
}
