.illustrator{

    text-align: center;
    padding: 1rem;
    /* align-content: center; */
}

.illustrator {
    color: white;
    /* The image used */
    background-image: url("../../Assets/bg_21.jpg");
  
    /* Set a specific height */
    min-height: 500px; 
  
    /* Create the parallax scrolling effect */
    background-attachment: fixed;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    /* background-color: rgba(250, 3, 3, 0.863); */
    
    /* background-color: rgba(0, 0, 0, 0.926); */
    
}
.illustrator-header{
    /* background-color: rgba(24, 24, 24, 0.745); */
    max-width: max-content;
    margin: auto;
    padding: 1rem 4rem;
}
.illustrator button{
    margin: 3rem auto;
}
.illustrator-icons{
    display: flex;
    justify-content: center;
    align-items: baseline;
    flex-wrap: wrap;
    margin: 3rem auto;
    font-weight: 500;
    
    background-color: rgba(74, 72, 72, 0.27);
}
.illustrator-icons article{
    margin: 1rem;
    width: 150px;
}

.illustrator-icons svg{
    font-size: 3rem;
    margin: 1rem;
    
}
.illustrator-icons > p::after{
    content: "";
    width: 100%;
    color: rgb(255, 255, 255);
    height: 3px;
    display: block;

}
@media screen and (max-width: 700px) {
    .illustrator-header {
        padding: 1rem;
    }
    
}




