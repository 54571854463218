li {
  padding: 0.5rem;
  /* border: 1px solid blue; */
  color: white;
  border-radius: 10px 0 10px 0;
  margin: 0.5rem;
  font-weight: 600;
  /* width: max-content; */
}

li::after {
  content: "";
  width: 0%;
  height: 2px;
  margin: 0.4rem;
  background-color: rgb(253, 181, 86);
  display: block;
  margin: 0.5rem auto 0 auto;
  transition: all 0.4s;
  /* background-color:var(--clr-primary); */
}

li:hover {
  color: var(--clr-primary);
  /* background-color: white; */
  /* background-color: var(--clr-primary); */
}
li:hover::after {
  width: 50%;
}

.dropdown-contents {
  display: none;
  position: absolute;
  flex-wrap: wrap;
  background-color: #e4e4e4;
  min-width: 65vw;
  max-width: 65vw;
  /* min-height: max-
    /* min-height: max-content; */
  top: 5%;
  left: 85%;
  box-shadow: 0px 8px 16px 0px rgb(0 0 0 / 20%);
  z-index: 5;
  padding: 0.5rem 2rem;
  overflow-y: scroll;
  height: 85%;
}
.dropdown-items li {
  color: black;
  font-weight: 500;
}

/* .dropdown-contents li{
    color: black;
    padding: 12px 16px;
    text-decoration: none;
    display: block;
  } */

.menu-items li:hover .dropdown-contents {
  display: flex;
  justify-content: center;
  align-items: baseline;
  flex-wrap: wrap;
  /* margin: 1rem; */
}

.dropdown-contents a:hover {
  background-color: #ddd;
}

.dropdown:hover .dropdown-contents {
  display: block;
}

.dropdown:hover .dropbtn {
  background-color: #3e8e41;
}

.dropdown-items {
  margin: 1rem;
  text-align: center;
}
@media screen and (min-width: 1500px) {
  .dropdown-contents {
    max-width: 1000px;
    min-width: 1000px;
  }
}

@media screen and (max-width: 750px) {
  .dropdown-items li {
    padding: 0.2;
  }
}
@media screen and (max-width: 550px) {
  .dropdown-contents {
    position: relative;
    left: 1%;
    width: 100%;
    /* height: 100vh; */
    /* right: 50%; */
  }
}
