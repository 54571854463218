@keyframes revealText {
  0% {
    opacity: 0;
    letter-spacing: 1em;
  }
  100% {
    opacity: 1;
    letter-spacing: normal;
  }
}

.loader {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.loading-text {
  font-size: 36px;
  font-weight: bold;
  color: orange;
  opacity: 0;
  animation: revealText 2s ease-in-out forwards;
}
