.features{
    padding: 3rem 1rem;
}
.features-content{
    margin: 2rem 1rem;
}

@media screen and (max-width: 700px) {
    .features{
        padding: 1rem;
    }
    .features-content{
        margin: 1rem;
    }
    
    
    
    
}