.services {
    padding: 1rem;
}

.services-items {
    display: flex;
    flex-direction: row;
    width: 100%;
    flex-wrap: wrap;
    justify-content: center;
    padding: 4rem 1rem;
}


.services-item {
    text-align: center;
    flex-basis: 30%;
    flex: 30% 30% auto;
    flex: 1 1 0;
    margin: 1rem;
    padding: 4rem 2rem;
    /* background: var(--clr-primary); */
    /* background: #58c6e2; */
    background: #f8fcfd;
    border: 1px solid var(--clr-secondary);

    border-radius: 30px 0 30px 0;
    
}
.services-item:hover{
    -webkit-box-shadow: 2px 2px 22px -3px rgba(0, 0, 0, 0.43);
    -moz-box-shadow: 2px 2px 22px -3px rgba(0, 0, 0, 0.43);
    box-shadow: 2px 2px 22px -3px rgba(0, 0, 0, 0.43);

}

.services-item a{
    color: rgb(84, 199, 208);
    color: var(--clr-secondary);

}
.services-item a svg{
    color:  rgb(0, 133, 174);;
    font-size: 3rem;
    /* color: var(--clr-secondary); */
    /* color: black; */
}

.services-item a h3 {
    font-size: 2rem;
    /* color: white;
    color: black; */
}

@media screen and (max-width: 700px) {
    .services-items {
        padding: 2rem 1rem;
    }



}