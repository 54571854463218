.footer-section{
    border-top: 1px solid var(--clr-primary);

}
.footer{
    padding: 1rem;
    /* color: white; */
    /* background-color: rgba(0, 0, 0, 0.521); */
}
.footer-up{
    display: flex;
    justify-content: space-around;
    align-items: center;
    padding: auto 0.5rem;
}
.footer-up h5{
    font-size: 1.5rem;
    
}
.footer-up h5 a{
    display: flex;
    justify-content: center;
    align-items: center;
    color: var(--clr-primary);
}
.footer-left{
    font-size: 1.5rem;
    cursor: pointer;
}
.footer-socials{
    display: flex;
    justify-content: flex-end;
    align-items: center;
}
.footer svg{
    font-size: 2.5rem;
    transition: ease all 0.3s;
    color: var(--clr-primary);
    border-radius: 10px;
    padding: 5px;
    cursor: pointer;
}
.footer-up svg{
    font-size: 2.5rem;
    transition: ease all 0.3s;
    color: var(--clr-primary);
    border-radius: 10px;
    padding: 5px;
    cursor: pointer;
}
.footer-socials svg:hover{
    transform: translateY(-5px);
}

.footer-content{
    display: flex;
    flex: 1;
    gap: 0.5rem;
    justify-content: space-evenly;
    align-items: flex-start;
    flex-wrap: wrap;
}

.footer-items{
    width: 200px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: baseline;
    margin: 1rem;

}

.footer-items p{
    word-wrap: break-word;
    overflow-wrap: break-word;
    margin: 0.5rem;

}
.footer-items > h5,
.footer-items > h5 a {
    display: flex;
    align-items: center;
    justify-content: center;
    color: var(--clr-primary);
}


.copy-rights{
    text-align: center;
}
.developer{
    margin: 0.5rem;
    text-align: center;
}
.developer p{
    font-size: 15px;
}
.developer p a{
    color: var(--clr-secondary);
}

@media screen and (max-width: 850px) {
    .footer-up{
        flex-direction: column;
    }
    
}
@media screen and (max-width: 650px) {
    .footer-items {
        margin: 0.5rem;
        /* color: bisque; */
    }
    
    
}



