*,
::after,
::before {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  scroll-behavior: smooth;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

:root {
  /* --color-bg:#d9fffc;
  --color-bg:#02cec0;
  --color-bg-dark:#a4a7a7e5;
  --color-bg-varient:#87fff7;
  --color-footer:#031b34;
  --color-primary:#00b9da;
  --color-primary-varient:#36beb5;
  --color-blog: #042c54;
  --color-text: #81AFDD;
  --color-subtext: #FF8A71;
  --color:#952aa1; */

  --clr-pr: #0096ff;
  --clr-pr1: #183efa;
  --clr-primary: #ff9100;
  --clr-primary-varient: #ffaa00dd;

  --clr-secondary: #38b6ff;

  --clr-light1: #ccb07f;
  --clr-light2: #dfceb5;

  --clr-prim: #f7a531;

  --box-shadow2: 2px 2px 14px 7px rgba(92, 87, 92, 0.49);
}

a {
  text-decoration: none;
  color: none;
}
ul,
li {
  list-style: none;
}
h1 {
  font-size: 3rem;
}
h2 {
  font-size: 2rem;
}
h3 {
  font-size: 1.25rem;
}
h4 {
  font-size: 0.875rem;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  display: flex;
  justify-content: center;
  align-items: center;
}

.gradient_bg {
  background: rgb(94, 94, 94);
  background: linear-gradient(
    0deg,
    rgba(94, 94, 94, 1) 0%,
    rgba(91, 72, 53, 0.9612219887955182) 48%,
    rgba(77, 48, 28, 1) 100%
  );
}

.gradient_bg2 {
  background: rgb(251, 123, 32);
  background: linear-gradient(
    90deg,
    rgba(251, 123, 32, 0.9360119047619048) 0%,
    rgba(251, 164, 32, 0.9416141456582633) 50%,
    rgba(251, 123, 32, 0.9444152661064426) 100%
  );
}
/* .title{
  color: var(--clr-primary);
} */
.sub-title {
  text-align: center;
  color: var(--clr-primary);
  font-size: 3rem;
  background-color: rgb(176 176 176 / 25%);
  max-width: max-content;
  margin: auto;
  padding: 1rem 4rem;
}
.btn {
  font-size: 0.9rem;
  color: white;
  font-weight: 600;
  padding: 0.6rem 3rem;
  background-color: var(--clr-primary);
  cursor: pointer;
  border-radius: 20px;
  border: 1px solid white;
}
.btn:hover {
  background-color: var(--clr-primary-varient);
  color: white;
}

@media screen and (max-width: 750px) {
  .page-main {
    text-align: center;
  }
  .page-main-points {
    gap: 0rem;
  }
  h1 {
    font-size: 2rem;
    /* font-size: 70%; */
  }
  h2 {
    font-size: 1.5rem;
  }
  h3 {
    font-size: 1rem;
  }
  h4 {
    font-size: 0.675rem;
  }
}

@media screen and (max-width: 650px) {
  .footer-items {
    margin: 0.5rem;
    /* color: bisque; */
  }
  .sub-title {
    font-size: 2rem;
    padding: 1rem 2rem;
  }
  .gradient_bg {
    background: rgb(94, 94, 94);
    background: linear-gradient(
      268deg,
      rgba(94, 94, 94, 1) 0%,
      rgba(91, 72, 53, 0.9612219887955182) 48%,
      rgba(77, 48, 28, 1) 100%
    );
  }
}

/* Example CSS for styling the sitemap */
.sitemap {
  font-family: Arial, sans-serif;
  padding: 20px;
  background-color: #f2f2f2;
}

.sitemap h2 {
  color: #333;
}

.sitemap ul {
  list-style-type: none;
}

.sitemap li {
  margin-bottom: 5px;
}

.sitemap a {
  text-decoration: none;
  color: #007bff;
}

.sitemap a:hover {
  text-decoration: underline;
  color: #0056b3;
}
