.backtotop button {
  position: fixed;
  bottom: 30px;
  right: 30px;
  height: 40px;
  width: 30px;

  font-size: 30px;
  z-index: 13;
  color: var(--clr-primary);
  outline: none;
  border: 1px solid var(--clr-primary);
  cursor: pointer;
  border-radius: 5px;
  background: #9b9b9b8a;
}
.backtotop button:hover {
  color: white;
  background-color: var(--clr-primary);
}
@media screen and (min-width: 1500px) {
  .backtotop button {
    left: auto;
    /* right: auto; */
  }
}
@media screen and (max-width: 550px) {
  .backtotop button {
    bottom: 30px;
    right: 30px;
    height: 30px;
    width: 20px;
  }
}
