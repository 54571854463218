.nav {
  position: fixed;
  z-index: 3;
  width: 20vw;
  height: 100%;
  top: 0;
  background-color: #d1d1d1;
  border: 1px solid var(--clr-primary);
}
/* .nav-geo{
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    max-height: fit-content;
    background-color: white;
}
.nav-geo p{
    margin: 0 1rem;
    display: flex;
    align-items: center;
    justify-content: center;
}

.nav-geo p a{
    display: flex;
    align-items: center;
    justify-content: center;
}
.nav-geo p svg{
    margin:0 0.3rem;

} */

.navbar {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}
.navbar-links {
  padding: 0.2rem 1rem;
}

.navbar-links_logo {
  margin: 0.5rem auto;
  width: 15vw;
  height: auto;
  /* position: fixed; */
  /* background-color: var(--clr-bg); */
  /* background-color: aqua; */
}

.navbar-links_logo img {
  width: 100%;
}

.navbar-links_container {
  flex: 1 1;
  text-align: center;
  overflow-y: scroll;
  /* height: 26%; */
  max-height: 50vh;
}
.navbar-menu_container-links {
  overflow-y: scroll;
  height: 70vh;
}

/* width */
::-webkit-scrollbar {
  width: 5px;
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 2px grey;
  border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: var(--clr-secondary);
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: var(--clr-primary-varient);
}

.navbar-links_container p,
.navbar-menu_container-links p {
  margin: 0 0.6rem;
  padding: 0.2rem 0.6rem;
  /* color: white; */
  align-items: center;
  font-family: var(--font-family);
  font-size: 12px;
  font-weight: 600;
  letter-spacing: 1px;
  opacity: 0.6;
  transition: all ease 0.6s;
}

.navbar-links_container p:hover {
  opacity: 1;
}

.navbar-menu {
  display: none;
  position: relative;
}

.navbar-menu svg {
  font-size: 2rem;
  cursor: pointer;
  color: white;
  z-index: 11;
  background: #e7e7e7;
  border-radius: 5px;
  padding: 6px;
}

.navbar-menu_container {
  display: flex;
  z-index: 1;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  /* text-align: center; */
  position: absolute;
  right: 0%;
  padding: 1rem 0.5rem;
  margin-top: 1rem;
  width: 90vw;
  border-radius: 5px;
  background: rgb(117, 115, 115);
  border: 1px solid grey;
  box-shadow: 0 0 5 rgba(0, 0, 0, 0.2);
}

.navbar-menu_container p {
  margin: 1rem 0;
}

.nav-socials {
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-wrap: wrap;
  padding: 2rem 1rem;
}
.nav-socials svg {
  font-size: 2rem;
  transition: ease all 0.3s;
  /* color: var(--clr-primary); */
  color: white;
  /* color: var(--clr-light1); */
  border-radius: 10px;
  padding: 2px;
  margin: 0.2rem;
}
.nav-socials svg:hover {
  transform: translateY(-5px);
  cursor: pointer;
  color: var(--clr-primary);
}

/* @media screen and (max-width: 1050px) {
    .datazip_navbar-links_container{
        display: none;
    }
} */

@media screen and (max-width: 700px) {
  .navbar {
    padding: 0.3rem 1rem;
  }
  .navbar-links_logo {
    margin: 0.3rem 1rem;
  }
}

@media screen and (max-width: 550px) {
  .nav {
    width: 100%;
    height: auto;
  }
  .navbar {
    flex-direction: row;
  }

  .nav-geo p {
    margin: 0 0.1rem;
    font-size: 9px;
  }
  .navbar-links_container {
    display: none;
  }

  .navbar-menu {
    display: flex;
    align-self: center;
  }

  .navbar {
    display: flex;
    padding: 0.2rem 1rem;
  }
  .navbar-links {
    padding: 0.2rem 0.6rem;
  }
  .navbar-links_logo {
    margin: 0.2rem 0;
    width: 20%;
  }
  .nav-socials {
    display: none;
  }
}
